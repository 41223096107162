<template>
  <v-app id="inspire">
    <v-overlay
      opacity="1"
      @click="overlay = false"
       v-touch="{
        move: () => overlay = false,
      }"
      z-index="10"
      :value="overlay"
      class="clickable d-flex justify-center height-100"
    >
      <div class="text-h1 mb-1">Kacper Kwaśny</div>
      <div class="text-h4 text-right font-weight-thin">kwasnyy.pl</div>
      <div style="position: fixed; right: 0; bottom: 0;" class="text-h6 text-right font-weight-thin ma-2">{{ translate(overlayContent.info) }}</div>
    </v-overlay>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase font-weight-light">
        {{ navbarTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      v-touch="{
        left: () => {
          this.drawer = false;
        },
      }"
    >
      <v-list nav dense class="d-flex flex-column" style="height: 100%">
        <!-- Drawer section CV -->
        <v-list-item-group>
          <v-subheader :class="style.drawer.header">{{
            sideBar && translate(sideBar.cvSection.title)
          }}</v-subheader>
          <v-list-item-content inactive>
            <v-btn
              :href="sideBar && sideBar.cvSection.open.link"
              target="_blank"
            >
              {{ sideBar && translate(sideBar.cvSection.open.text) }}
            </v-btn>
          </v-list-item-content>
          <v-list-item-content inactive>
            <v-btn :href="sideBar && sideBar.cvSection.download.link">
              {{ sideBar && translate(sideBar.cvSection.download.text) }}
            </v-btn>
          </v-list-item-content>
        </v-list-item-group>
        <v-divider class="my-4"></v-divider>

        <!-- Drawer section Contact -->
        <v-list-item-group v-if="sideBar">
          <v-subheader :class="style.drawer.header">{{
            sideBar && translate(sideBar.contactSection.title)
          }}</v-subheader>
          <v-list-item
            v-for="(contact, n) in sideBar.contactSection.contacts"
            :key="n"
            :href="contact.href"
            target="_blank"
            @click="
              contact.copyClipboard &&
                copyToClipboard(contact.copyClipboard, translate(contact.alert))
            "
          >
            <v-list-item-icon>
              <v-icon right>mdi-{{ contact.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ translate(contact.name) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4"></v-divider>

        <!-- Drawer section Settings -->
        <v-list-item-group style="margin-top: auto">
          <v-subheader :class="style.drawer.header + ' align-self-baseline'">{{
            sideBar && translate(sideBar.settingsSection.title)
          }}</v-subheader>
          <v-list-item @click="toggleThemeMode">
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                translate(sideBar && sideBar.settingsSection.switchTheme)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-select
              v-model="userLanguage"
              @change="setUserLang"
              :items="languages"
              single-line
            ></v-select>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main
      v-touch="{
        left: () => swipedTabsRight(false),
        right: () => swipedTabsRight(true),
      }"
    >
      <v-tabs v-model="activeSection">
        <v-tab v-for="(section, n) in sections" :key="n">
          {{ translate(section.title) }}
        </v-tab>
      </v-tabs>

      <v-container v-for="(section, n) in sections" :key="n" style="padding: 0">
        <v-container v-show="activeSection == n">
          <v-row>
            <v-col
              v-for="(tile, m) in section.tiles"
              :key="m"
              cols="12"
              sm="6"
              lg="4"
              @click="tile.dialogOpen = true"
              :class="[tile.popUp !== undefined ? 'scale-on-hover' : '']"
            >
              <v-card height="">
                <v-img
                  :src="tile.thumbnail"
                  contain
                  :height="tile.imgHeight || '5em'"
                  :position="tile.imgPosition || 'center right'"
                  :style="tile.imgStyle || ''"
                >
                  <v-card-title>
                    {{ translate(tile.title) }}
                  </v-card-title>
                </v-img>
                <v-card-text>
                  {{ translate(tile.text) }}
                </v-card-text>
                <PopUp
                  v-if="tile.popUp"
                  :title="translate(tile.title)"
                  :sections="tile.popUp.sections.map(translate)"
                  :dialogOpen="tile.dialogOpen"
                  @closeDialog="tile.dialogOpen = false"
                  :img="tile.popUp.img ? tile.popUp.img : tile.thumbnail"
                  :imgHeight="tile.popUp.imgHeight || '10em'"
                  :showTitle="tile.popUp.showTitle"
                  :closeColor="tile.popUp.closeColor"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { loadContent } from "./load_data/remote_content";
import {
  getLanguage,
  setLanguage,
  getDarkThemeOn,
  setDarkThemeOn,
} from "./load_data/user_settings";
import { language } from "./funcs/language";
import PopUp from "./components/PopUp.vue";

export default {
  data() {
    return {
      title: "Portfolio",
      drawer: window.innerWidth > 1262,
      overlay: true,
      idleTime: 0,
      interval: 500,
      inactivity: 1000 * 60 * 5,

      group: null,
      sections: [],
      sideBar: null,
      overlayContent: null,
      activeSection: 0,
      userLanguage: "en",
      languages: [
        { value: "en", text: "English" },
        { value: "pl", text: "Polski" },
      ],
      navbarTitle: process.env.VUE_APP_NAVBAR_TITLE,
      style: {
        drawer: {
          header: "text-h6 font-weight-light",
        },
        sections: {
          card: "",
        },
      },
    };
  },
  methods: {
    async toggleThemeMode() {
      const darkOn = await getDarkThemeOn();
      await setDarkThemeOn(!darkOn);
      this.$vuetify.theme.dark = !darkOn;
    },
    translate(textObj) {
      return language(textObj, this.userLanguage);
    },
    fullLanguageName(shortcut) {
      console.log(shortcut);
      switch (shortcut) {
        case "pl":
          return "Polski";
        case "en":
          return "English";
        default:
          return shortcut;
      }
    },
    async setUserLang(newValue) {
      console.log("setUserLang: newValue:", newValue);
      await setLanguage(newValue);
    },
    copyToClipboard(text, info) {
      console.log("copyToClipboard");
      console.log(navigator);
      console.log(navigator.clipboard);
      navigator.clipboard.writeText(text);
      if (info) {
        alert(info);
      }
    },
    swipedTabsRight(right) {
      if (right) {
        // open drawer if allready on far left
        if (this.activeSection === 0) {
          this.drawer = true;
        }

        const newSection = this.activeSection - 1;
        this.activeSection = newSection >= 0 ? newSection : this.activeSection;
      } else {
        const newSection = this.activeSection + 1;
        this.activeSection =
          newSection < this.sections.length ? newSection : this.activeSection;
      }
    },
    overlayInactivitySetup() {
      this.idleTime = 0;
      document.addEventListener("mousemove", this.resetIdle, false);
      document.addEventListener("keypress", this.resetIdle, false);
      document.addEventListener("keydown", this.keyDownListener, false);
      setInterval(this.checkIfIdle, this.interval);
    },
    checkIfIdle() {
      this.idleTime += this.interval;
      if (this.idleTime >= this.inactivity) {
        this.overlay = true;
      }
    },
    resetIdle() {
      this.idleTime = 0;
    },
    keyDownListener(e) {
      // if overlay is open any pressing every key will result in closing the overlay
      if (this.overlay) {
        this.overlay = false;
        return
      }

      // open overlay on escape
      if (e.key === "Escape") {
        this.overlay = true;
      }

    },
  },
  async created() {
    // get content from remote server
    const content = await loadContent("/content.json");
    if (content === null) {
      this.sections = null;
    }
    this.sections = content.sections
      .map((val) => {
        return {
          ...val,
          tiles: val.tiles.map((tval) => {
            return {
              dialogOpen: false,
              ...tval,
            };
          }),
        };
      })
      .filter((v) => !v.notToBeDisplayed);
    this.sideBar = content.sideBar;
    this.overlayContent = content.overlayContent

    // get language.
    // currently read from localStorage,
    // but TODO to make it reade from iframe of kwasnyy.pl
    this.userLanguage = await getLanguage();
    // get theme from remote server
    this.$vuetify.theme.dark = await getDarkThemeOn();

    // setup overlay timer for it to come down after inactivity
    this.overlayInactivitySetup();
  },
  computed: {
    userLanguageFull() {
      return this.userLanguage === "pl" ? "Polski" : "English";
    },
  },
  components: { PopUp },
};
</script>

<style lang="scss">
.scale-on-hover {
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.clickable {
  cursor: pointer;
}
</style>