export async function getDarkThemeOn() {
    const localDarkOn = getDarkThemeOnLocalStorage()
    try {
        // TODO get from remote server
        return localDarkOn
    } catch (_) {
        return localDarkOn
    }
}

export function getDarkThemeOnLocalStorage() {
    const themeKey = process.env.VUE_APP_COLOR_THEME
    const dark = window.localStorage.getItem(themeKey)
    try {
        return JSON.parse(dark)
    } catch (e) {
        return true
    }
}

export async function setDarkThemeOn(on) {
    const themeKey = process.env.VUE_APP_COLOR_THEME
    window.localStorage.setItem(themeKey, JSON.stringify(on))
}


export async function getLanguage() {
    const languageKey = process.env.VUE_APP_USER_LANGUAGE
    const lang = window.localStorage.getItem(languageKey)
    if (lang === null) {
        window.localStorage.setItem(languageKey, "en")
        return "en"
    }
    return lang
}

export async function setLanguage(langShort) {
    langShort = langShort.toLowerCase()
    if (!['en', 'pl'].includes(langShort)) {
        throw new Error("Invalid language")
    }
    const languageKey = process.env.VUE_APP_USER_LANGUAGE
    window.localStorage.setItem(languageKey, langShort)
}