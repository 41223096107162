// Input props:
//      textObj: {
//          en: "text in english",
//          pl: "tekst po polsku"    
//      } 
export function language(textObj, lang) {
    if (textObj === undefined) return ""
    if (typeof textObj === 'string' || textObj instanceof String) {
        // textObj is just a string,
        // this is for when the text in polish and english is the same.
        // Example: "Portfolio"
        return textObj
    }
    if (lang === "pl") {
        return textObj.pl
    }

    return textObj?.en
}