var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-overlay',{directives:[{name:"touch",rawName:"v-touch",value:({
      move: function () { return _vm.overlay = false; },
    }),expression:"{\n      move: () => overlay = false,\n    }"}],staticClass:"clickable d-flex justify-center height-100",attrs:{"opacity":"1","z-index":"10","value":_vm.overlay},on:{"click":function($event){_vm.overlay = false}}},[_c('div',{staticClass:"text-h1 mb-1"},[_vm._v("Kacper Kwaśny")]),_c('div',{staticClass:"text-h4 text-right font-weight-thin"},[_vm._v("kwasnyy.pl")]),_c('div',{staticClass:"text-h6 text-right font-weight-thin ma-2",staticStyle:{"position":"fixed","right":"0","bottom":"0"}},[_vm._v(_vm._s(_vm.translate(_vm.overlayContent.info)))])]),_c('v-app-bar',{attrs:{"app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"text-uppercase font-weight-light"},[_vm._v(" "+_vm._s(_vm.navbarTitle)+" ")]),_c('v-spacer')],1),_c('v-navigation-drawer',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () {
        this$1.drawer = false;
      },
    }),expression:"{\n      left: () => {\n        this.drawer = false;\n      },\n    }"}],attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',[_c('v-subheader',{class:_vm.style.drawer.header},[_vm._v(_vm._s(_vm.sideBar && _vm.translate(_vm.sideBar.cvSection.title)))]),_c('v-list-item-content',{attrs:{"inactive":""}},[_c('v-btn',{attrs:{"href":_vm.sideBar && _vm.sideBar.cvSection.open.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.sideBar && _vm.translate(_vm.sideBar.cvSection.open.text))+" ")])],1),_c('v-list-item-content',{attrs:{"inactive":""}},[_c('v-btn',{attrs:{"href":_vm.sideBar && _vm.sideBar.cvSection.download.link}},[_vm._v(" "+_vm._s(_vm.sideBar && _vm.translate(_vm.sideBar.cvSection.download.text))+" ")])],1)],1),_c('v-divider',{staticClass:"my-4"}),(_vm.sideBar)?_c('v-list-item-group',[_c('v-subheader',{class:_vm.style.drawer.header},[_vm._v(_vm._s(_vm.sideBar && _vm.translate(_vm.sideBar.contactSection.title)))]),_vm._l((_vm.sideBar.contactSection.contacts),function(contact,n){return _c('v-list-item',{key:n,attrs:{"href":contact.href,"target":"_blank"},on:{"click":function($event){contact.copyClipboard &&
              _vm.copyToClipboard(contact.copyClipboard, _vm.translate(contact.alert))}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-"+_vm._s(contact.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate(contact.name))+" ")])],1)],1)})],2):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('v-list-item-group',{staticStyle:{"margin-top":"auto"}},[_c('v-subheader',{class:_vm.style.drawer.header + ' align-self-baseline'},[_vm._v(_vm._s(_vm.sideBar && _vm.translate(_vm.sideBar.settingsSection.title)))]),_c('v-list-item',{on:{"click":_vm.toggleThemeMode}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.translate(_vm.sideBar && _vm.sideBar.settingsSection.switchTheme)))])],1)],1),_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.languages,"single-line":""},on:{"change":_vm.setUserLang},model:{value:(_vm.userLanguage),callback:function ($$v) {_vm.userLanguage=$$v},expression:"userLanguage"}})],1)],1)],1)],1),_c('v-main',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.swipedTabsRight(false); },
      right: function () { return _vm.swipedTabsRight(true); },
    }),expression:"{\n      left: () => swipedTabsRight(false),\n      right: () => swipedTabsRight(true),\n    }"}]},[_c('v-tabs',{model:{value:(_vm.activeSection),callback:function ($$v) {_vm.activeSection=$$v},expression:"activeSection"}},_vm._l((_vm.sections),function(section,n){return _c('v-tab',{key:n},[_vm._v(" "+_vm._s(_vm.translate(section.title))+" ")])}),1),_vm._l((_vm.sections),function(section,n){return _c('v-container',{key:n,staticStyle:{"padding":"0"}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSection == n),expression:"activeSection == n"}]},[_c('v-row',_vm._l((section.tiles),function(tile,m){return _c('v-col',{key:m,class:[tile.popUp !== undefined ? 'scale-on-hover' : ''],attrs:{"cols":"12","sm":"6","lg":"4"},on:{"click":function($event){tile.dialogOpen = true}}},[_c('v-card',{attrs:{"height":""}},[_c('v-img',{style:(tile.imgStyle || ''),attrs:{"src":tile.thumbnail,"contain":"","height":tile.imgHeight || '5em',"position":tile.imgPosition || 'center right'}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate(tile.title))+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.translate(tile.text))+" ")]),(tile.popUp)?_c('PopUp',{attrs:{"title":_vm.translate(tile.title),"sections":tile.popUp.sections.map(_vm.translate),"dialogOpen":tile.dialogOpen,"img":tile.popUp.img ? tile.popUp.img : tile.thumbnail,"imgHeight":tile.popUp.imgHeight || '10em',"showTitle":tile.popUp.showTitle,"closeColor":tile.popUp.closeColor},on:{"closeDialog":function($event){tile.dialogOpen = false}}}):_vm._e()],1)],1)}),1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }