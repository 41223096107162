import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { getDarkThemeOnLocalStorage } from '../load_data/user_settings'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        dark: getDarkThemeOnLocalStorage()
    }
});