<template>
  <v-dialog v-model="show" width="80vw" max-width="600px">
    <v-card>
      <v-img  :src="img" :height="imgHeight">
        <v-card-actions>
          <v-card-title v-if="showTitle">
            {{ title }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false">
            <v-icon :color="closeColor"> mdi-close </v-icon>
          </v-btn>
        </v-card-actions>
      </v-img>
      <div v-for="(s, n) in sections" :key="n">
        <v-divider class="mb-2"></v-divider>
        <v-card-text> {{ s }} </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    sections:  Array,
    dialogOpen: Boolean,
    img: String,
    imgHeight: String,
    showTitle: Boolean,
    closeColor: String,
  },

  computed: {
    show: {
      get() {
        return this.dialogOpen;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
};
</script>